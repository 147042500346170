<script setup>
const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  type: {
    type: String,
    default: 'text'
  },
  label: {
    type: String,
    default: undefined
  },
  modelValue: {
    type: [Number, String],
    default: undefined
  },
  helpText: {
    type: String,
    default: undefined
  },
  errorMessage: {
    type: String,
    default: undefined
  },
  icon: {
    type: String,
    default: undefined
  },
  valuePrefix: {
    type: String,
    default: undefined
  },
  showError: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: 'major'
  },
});

const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  }
});

</script>

<template>
  <UiFormControl v-bind="props">
    <div 
      class="ui-input" 
      v-bind:class="[
        `ui-input--type-${props.type}`, 
        `ui-input--${props.size}`, 
        { 
          'ui-input--icon': props.icon, 
          'ui-input--prefix': props.valuePrefix, 
          'ui-input--show-error': props.showError 
        }
      ]">

      <span class="ui-input__prefix" v-if="props.valuePrefix">{{ props.valuePrefix }}</span>

      <input 
        v-bind:type="props.type" 
        class="ui-input__input" 
        v-bind="$attrs"
        v-model="model">

      <UiIcon 
        class="ui-input__icon" 
        v-bind:name="props.icon" 
        v-if="props.icon">
      </UiIcon>

    </div>
  </UiFormControl>
</template>

<style lang="scss">
.ui-input {
  --s-bezel-x:var(--s-input-bezel-x);
  --s-bezel-y:var(--s-input-bezel-y);
  --s-focus-width:var(--s-outline-width);
  --s-border:var(--s-border-width);
  --s-border-radius:var(--s-border-radius);
  --s-max-heigh:var(--s-input-max-height);
  --s-bezel-icon-x:var(--s-input-icon-bezel-x);
  --s-bezel-icon-y:var(--s-input-icon-bezel-y);
  --s-icon:var(--s-icon-size--m);
  --c-border:var(--c-input-border);
  --c-focus:var(--c-interaction-focus);

  position: relative;
}

.ui-input--minor {
  --s-bezel-x:var(--s-input-bezel-x-minor);
  --s-bezel-y:var(--s-input-bezel-y-minor);
  --s-focus-width:var(--s-outline-width);
  --s-max-heigh:var(--s-input-max-height-minor);
  --s-bezel-icon-x:var(--s-input-icon-bezel-x-minor);
  --s-bezel-icon-y:var(--s-input-icon-bezel-y-minor);
  --s-icon:var(--s-icon-size--s);
}

.ui-input__input {
  @include input;

  & {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: calc(var(--s-bezel-y) - var(--s-border)) var(--s-bezel-x);
    border: var(--s-border) solid var(--c-border);
    border-radius: var(--s-border-radius);
    max-height: var(--s-max-heigh); // fix inconsistent input heights (date)
  }
}

.ui-input--icon .ui-input__input {
  padding-left: calc(var(--s-bezel-icon-x) * 1.5 + var(--s-icon));
}

.ui-input--minor .ui-input__input {
  @include small-text;
}

.ui-input--show-error {
  --c-border: var(--s-input-error);
  --c-focus: var(--s-interaction-focus-error);
}

.ui-input__input:focus {
  position: relative;
  outline: var(--s-focus-width) solid var(--c-focus);
  z-index: 1;
}

.ui-input__input::placeholder {
  color: var(--c-input-placeholder)
}

.ui-input__icon {
  position: absolute;
  top: var(--s-bezel-icon-y);
  left: var(--s-bezel-icon-x);
  width: var(--s-icon);
  height: var(--s-icon);
  z-index: 1;
}

.ui-input.ui-input--icon .ui-input__input::-webkit-calendar-picker-indicator {
  display: none; // hide datepicker icon
}

// prefix
.ui-input--prefix {
  display: flex;
}

.ui-input--prefix .ui-input__input {
  padding-left: calc(0.5 * var(--s-bezel-x));
  border-left: none;
}

.ui-input__prefix {
  @include input;

  & {
    padding: calc(var(--s-bezel-y) - var(--s-border)) var(--s-bezel-x);
    padding-right: calc(0.5 * var(--s-bezel-x));
    border: var(--s-border) solid var(--c-border);
    border-right: none;
    white-space: nowrap;
  }
}
</style>
