<script setup>
const props = defineProps({
  label: {
    type: String,
    default: undefined
  },
  helpText: {
    type: String,
    default: undefined
  },
  errorMessage: {
    type: String,
    default: undefined
  },
  showError: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: 'major'
  },
});
</script>

<template>
  <label class="ui-form-control" v-bind:class="[`ui-form-control--${props.size}`, {'ui-form-control--show-error': props.showError}]">
    <span class="ui-form-control__label" v-if="props.label" v-html="props.label"></span>
    <slot />
    <span class="ui-form-control__help-text" v-if="props.helpText" v-html="props.helpText"></span>
    <span class="ui-form-control__error-message" v-if="props.errorMessage" v-html="props.errorMessage"></span>
  </label>
</template>

<style lang="scss">
.ui-form-control {
  --s-label-stack: var(--s-label-stack);
  --c-text: var(--c-interaction-default);

  display: inline-block;
  margin-top: var(--s-input-stack);
  min-width: 100%;
}

.ui-form-control--minor {
  --s-label-stack: var(--s-label-stack-minor);
}

.ui-form-control--show-error {
  --c-text: var(--c-input-error);
}

.ui-form-control__label {
  display: block;
  margin-bottom: var(--s-label-stack);
  color: var(--c-text);
}

.ui-form-control--minor .ui-form-control__label,
.ui-form-control--minor .ui-form-control__help-text,
.ui-form-control--minor .ui-form-control__error-message {
  @include small-text;
}

.ui-form-control__help-text,
.ui-form-control__error-message {
  display: block;
  margin-top: var(--s-label-stack);
}

.ui-form-control__help-text + .ui-form-control__error-message {
  margin-top: 0;
}

.ui-form-control__error-message {
  color: var(--c-text);
}
</style>
